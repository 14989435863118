import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-05";
import { Container } from "@ui/wrapper";
import {
    StyledSection,
    StyledContent,
    StyledBlogWrap,
} from "../../templates/single-blog/style";

const CareersPage = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo title="Stellenangebote" />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="Stellenangebote"
                />
                <StyledSection>
                    <Container>
                        <StyledBlogWrap>
                            <StyledContent
                                dangerouslySetInnerHTML={{
                                    // language=HTML
                                    __html: `<p>Damit wir auch in Zukunft den wachsenden Anforderungen des Marktes gewachsen sind, benötigen wir qualifizierte und motivierte Mitarbeiter</p>
<p>Es erwartet Sie ein junges Team, ein hervorragendes Betriebsklima und Freiraum für Eigeninitiative.</p>
<p>Zur Verstärkung unserer Belegschaft suchen wir:</p>
<p>Mitarbeiter bzw. Mitarbeiterinnen mit folgenden Qualifizierungsprofilen</p>
<h3>Meister (m/w/d) oder Ingenieur (m/w/d) der Elektrotechnik</h3>
<h4>Stellenbeschreibung</h4>
<ul>
    <li>Planung und Aufbau von Datennetzen im Bereich der Kupfer und Glasfasertechnik</li>
    <li>Planung und Montage von Elektroinstallationen</li>
    <li>Abnahmen der Netze und Dokumentationen</li>
    <li>Fehleranalyse und Wiederherstellung im Störungsfall</li>
</ul>
<h4>Ihr Profil</h4>
<ul>
    <li>Ausbildung als Elektrotechniker oder Elektroinstallateur</li>
    <li>Abschluss der Meisterprüfung oder Ingenieur (Bachelor) im Bereich Elektrotechnik</li>
    <li>Kenntnisse auf CAD Programmen wie AutoCAD von Vorteil jedoch nicht Voraussetzung</li>
    <li>Kenntnisse mit Planungstools wie Elcom oder vergleichbar wünschenswert Kenntnisse auf dem Gebiet elektronische/digitale Messtechnik</li>
</ul>

<h3>Elektroinstallateur (m/w/d)</h3>
<h4>Stellenbeschreibung</h4>
<ul>
    <li>Aufbau von Datennetzen im Bereich der Kupfer und Glasfasertechni</li>
    <li>Montage von Elektroinstallationen und Infrastruktur Systeme</li>
</ul>

<h4>Ihr Profil</h4>
<ul>
    <li>Ausbildung als Elektrotechniker oder Elektroinstallateur</li>
    <li>Auch Berufsanfänger</li>
</ul>

<h4>Wir bieten Ihnen als Mitarbeiter (m/w/d)</h4>
<ul>
    <li>Übertarifliche und pünktliche Bezahlung</li>
    <li>Einen unbefristeten Arbeitsvertrag</li>
    <li>Persönliche Einarbeitung durch unsere Mitarbeiter</li>
</ul>

 
<p>Wir erwarten Teamfähigkeit, gute Umgangsformen, schnelle Auffassungsgabe und die Bereitschaft sich offen mit neuen Techniken auseinanderzusetzen.</p>
<p>Gerne unterstützen wir Sie mit schulischen Maßnahmen, die auch überbetrieblich stattfinden können.</p>
<p>Sollten wir mit den Stellenbeschreibungen Ihr Interesse geweckt haben, bitten wir Sie sich mit uns in Verbindung zu setzen. Bitte richten Sie Ihre schriftliche Bewerbung an:</p>
<p>Junghans Datennetztechnik GmbH<br/>
Herrn Holger Junghans<br/>
Bachstraße 22<br/>
65830 Kriftel<br/>
</p>
`
                                }}
                            />
                        </StyledBlogWrap>
                    </Container>
                </StyledSection>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query CareersPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderOne
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "stellenangebote" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
    }
`;

CareersPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default CareersPage;
